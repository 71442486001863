import gql from "graphql-tag";

export const MANAGER_APPROVER_EXPENSE = gql`
  query employee($id: ID!) {
    employee(id: $id) {
      pendingApprovalExpenseRequests {
        id
        note
        approved
        nextApprover
        expense {
          name
          type {
            name
          }
          employee {
            id
            firstName
            lastName
            profile {
              imageUrl
            }
            employment {
              branch {
                name
              }
            }
          }
          requested {
            amount
            code
          }
          attachment
          requestStatus
          timestamp
          expenseManagement {
            id
            name
            project {
              name
              color
            }
            employee {
              id
              firstName
              lastName
              profile {
                imageUrl
              }
              employment {
                branch {
                  name
                }
              }
            }
          }
          travel {
            id
            beginDate
            endDate
            startWorkDate
            employee {
              id
              firstName
              lastName
              profile {
                imageUrl
              }
              employment {
                branch {
                  name
                }
              }
            }
            address
            country
            city
            region
            requestStatus
          }
        }
      }
    }
  }
`;
